<template>
  <div>
    <b-row class="d-block text-center">
      <b-col
        lg="12"
        xl="12"
        class="d-block m-auto catogory"
      >
        <h2 class="title-products mt-4 mb-3">
          Nuestras marcas
        </h2>
        <b-row>
          <b-col class="col-lg-2 col-md-4 col-6">
            <div class="gradient-border">
              <b-link
                class="category-button btn btn-block"
                :to="{ name: 'products', params: { brand: 1 } }"
              >
                LTH
              </b-link>
            </div>

          </b-col>
          <b-col class="col-lg-3 col-md-4 col-6">
            <div class="gradient-border">
              <b-link
                class="category-button btn btn-block"
                :to="{ name: 'products', params: { brand: 2 } }"
              >
                LTH Motobatería
              </b-link>
            </div>
          </b-col>
          <b-col class="col-lg-2 col-md-4 col-6">
            <div class="gradient-border">
              <b-link
                class="category-button btn btn-block"
                :to="{ name: 'products', params: { brand: 3 } }"
              >
                América
              </b-link>
            </div>
          </b-col>
          <b-col class="col-lg-3 col-md-4 col-6">
            <div class="gradient-border">
              <b-link
                class="category-button btn btn-block"
                :to="{ name: 'products', params: { brand: 13 } }"
              >
                LTH Lubricantes
              </b-link>
            </div>
          </b-col>
          <b-col class="col-lg-2 col-md-4 col-6">
            <div class="gradient-border">
              <b-link
                class="category-button btn btn-block"
                :to="{ name: 'products', params: { brand: 14 } }"
              >
                LTH AGM
              </b-link>
            </div>
          </b-col>
          <!--          <b-col class="col-lg-2 col-md-4 col-6">
            <div class="gradient-border">
              <b-link
                class="category-button btn btn-block"
                :to="{ name: 'products', params: { category: 'otros/oficina' } }"
              >
                Libretas
              </b-link>
            </div>
          </b-col>-->
          <b-col class="col-lg-12">
            <div class="text-center mt-2">
              <b-link to="products">
                <feather-icon
                  icon="ArrowDownIcon"
                  size="16"
                />
                Ver todas</b-link> <!--todo:Revisar y hacer la funcion-->
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <br>

    <b-carousel
      id="carousel-example-generic"
      controls
      indicators
    >
      <b-carousel-slide>
        <img
          slot="img"
          class="img-fluid w-100 d-block"
          src="@/assets/images/slider/redesign/BANNER LTH PROMOSHOP2_Mesa de trabajo 1.jpg"
          alt="image slot"
          loading="lazy"
        >
      </b-carousel-slide>
      <b-carousel-slide>
        <img
          slot="img"
          class="img-fluid w-100 d-block"
          src="@/assets/images/slider/redesign/BANNER LTH PROMOSHOP2-03.jpg"
          alt="image slot"
          loading="lazy"
        >
      </b-carousel-slide>
      <b-carousel-slide>
        <img
          slot="img"
          class="img-fluid w-100 d-block"
          src="@/assets/images/slider/redesign/BANNER LTH PROMOSHOP2-02.jpg"
          alt="image slot"
          loading="lazy"
        >
      </b-carousel-slide>
    </b-carousel>
    <section class="bg-grey bg p-1">
      <b-row class="content-wrapper row d-block text-center product-fav">
        <b-col
          lg="12"
          xl="11"
          class="d-block m-auto catogory"
        >
          <h2 class="title-products mt-4 mb-3">
            Los favoritos de nuestros clientes
          </h2>
          <b-row>
            <b-col
              xl="4"
              lg="6"
              md="12"
              class="mb-3"
            >
              <div class="image-container">
                <img
                  loading="lazy"
                  src="@/assets/images/home/fav/hieleras.png"
                  alt="Descripción de tu producto"
                >
                <div class="overlay-content">
                  <div class="category-text">
                    Hieleras, <br> bolsos y <br> mochilas
                  </div>
                  <b-link
                    class="btn btn-primary"
                    :to="{ name: 'products', params: { category: 'recipientes-termicos/hieleras' } }"
                  >
                    Ver Más
                  </b-link>
                </div>
              </div>

            </b-col>
            <b-col
              xl="4"
              lg="6"
              md="12"
              class="mb-3"
            >
              <div class="image-container">
                <img
                  loading="lazy"
                  src="@/assets/images/home/fav/celular.png"
                  alt="Descripción de tu producto"
                >
                <div class="overlay-content">
                  <div class="category-text">
                    Accesorios <br> para celular
                  </div>
                  <b-link
                    class="btn btn-primary"
                    :to="{ name: 'products', params: { category: 'tecnologia/tecnologia' } }"
                  >
                    Ver Más
                  </b-link>
                </div>
              </div>

            </b-col>
            <b-col
              xl="4"
              lg="6"
              md="12"
              class="mb-3"
            >
              <div class="image-container">
                <img
                  loading="lazy"
                  src="@/assets/images/home/fav/carro.png"
                  alt="Descripción de tu producto"
                >
                <div class="overlay-content">
                  <div class="category-text">
                    Accesorios <br> para carro
                  </div>
                  <b-link
                    class="btn btn-primary"
                    :to="{ name: 'products', params: { category: 'motocicletas/motocicletas' } }"
                  >
                    Ver Más
                  </b-link>
                </div>
              </div>

            </b-col>
            <b-col
              xl="4"
              lg="6"
              md="12"
              class="mb-3"
            >
              <div class="image-container">
                <img
                  loading="lazy"
                  src="@/assets/images/home/fav/cocina.png"
                  alt="Descripción de tu producto"
                >
                <div class="overlay-content">
                  <div class="category-text">
                    Cocina
                  </div>
                  <b-link
                    class="btn btn-primary"
                    :to="{ name: 'products', params: { category: 'exteriores/exteriores' } }"
                  >
                    Ver Más
                  </b-link>
                </div>
              </div>

            </b-col>
            <b-col
              xl="4"
              lg="6"
              md="12"
              class="mb-3"
            >
              <div class="image-container">
                <img
                  loading="lazy"
                  src="@/assets/images/home/fav/oficina.png"
                  alt="Descripción de tu producto"
                >
                <div class="overlay-content">
                  <div class="category-text">
                    Oficina
                  </div>
                  <b-link
                    class="btn btn-primary"
                    :to="{ name: 'products', params: { category: 'otros/oficina' } }"
                  >
                    Ver Más
                  </b-link>
                </div>
              </div>

            </b-col>
            <b-col
              xl="4"
              lg="6"
              md="12"
              class="mb-3"
            >
              <div class="image-container">
                <img
                  loading="lazy"
                  src="@/assets/images/home/fav/viaje.png"
                  alt="Descripción de tu producto"
                >
                <div class="overlay-content">
                  <div class="category-text">
                    Para viajar
                  </div>
                  <b-link
                    class="btn btn-primary"
                    :to="{ name: 'products', params: { category: 'exteriores/exteriores' } }"
                  >
                    Ver Más
                  </b-link>
                </div>
              </div>

            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </section>

    <section class="bg">
      <b-row class="content-wrapper row">
        <b-col
          lg="12"
          xl="11"
          class="d-block m-auto"
        >
          <h2 class="title-products mt-4 mb-3">
            Liquidación de promocionales
          </h2>
          <b-row>

            <b-col
              v-for="product in products"
              :key="product.id"
              lg="3"
              md="6"
              sm="12"
              class="mb-3"
            >
              <div class="content-item">
                <b-link
                  class="d-block m-auto"
                  :to="{ name: 'product-details', params: { slug: product.slug } }"
                >
                  <div class="img-overlay">
                    <span
                      v-if="product.discount_price"
                      class="float-discont"
                    >-{{ ((convertCurrencyToFloat(product.discount_price)-convertCurrencyToFloat(product.price))/convertCurrencyToFloat(product.discount_price)*100).toFixed(2) }}%</span>
                    <span v-if="product.isFastShipping" class="float-fast-shipping"><img :src="require('@/assets/images/icons/fast-delivery.svg')" alt="Envío rápido" width="25"></span>
                    <img
                      loading="lazy"
                      :src="product.thumbnails[0]"
                      :alt="`${product.name}-${product.id}`"
                      class="img-overlay-image"
                    >
                    <div class="overlay">
                      <div class="text">
                        Más información
                      </div>
                    </div>
                  </div>
                </b-link>

                <div class="item-rating">
                  <ul class="unstyled-list list-inline">
                    <div v-if="product.free_shipping_enabled">
                      <b-badge
                        v-if="product.qty_free_shipping === 1"
                        pill
                        variant="light-success"
                      >
                        Envío gratis
                      </b-badge>

                      <b-badge
                        v-else
                        variant="light-success"
                      ><!-- todo: aquí hay que ponerlo decolores segun el articulo-->
                        Envío gratis a partir de {{ product.qty_free_shipping }} paq.
                      </b-badge>
                    </div>
                  </ul>
                </div>
                <div>
                  <p class="item-description mb-0">
                    {{ product.name }}
                  </p>
                  <h4 class="item-price">
                    ${{ product.price }} C/U
                    <strike
                      v-if="product.discount_price"
                      class="small"
                    >
                      ${{ product.discount_price }} C/U
                    </strike>
                  </h4>
                </div>

              </div>

            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </section>

    <section class="bg-grey bg">
      <b-row class="content-wrapper row">
        <b-col
          lg="12"
          xl="11"
          class="d-block m-auto"
        >

          <h2 class="title-products mt-4 mb-3">
            Experiencias de compra de nuestros clientes <!--todo:revisar gradiente-->
          </h2>
          <b-row>
            <b-col
              lg="6"
              md="12"
              class="mb-3"
            >
              <b-row>
                <b-col
                  lg="6"
                  md="12"
                  class="mb-3"
                >

                  <div class="blockquote">
                    <i class="quote-mark">"</i>Quiero expresar que el servicio y atención que he recibido en todo este tiempo que hemos utilizado la plataforma de Promoshop para realizar compras ha sido de lo mejor, en cuanto a atención, servicio y sobre todo muy atentos a nuestros comentarios.<i class="quote-mark">"</i>
                    <p> <b>Eduardo Quiroz</b> <br>
                      Acumuladores de autos morelos</p>
                  </div>

                </b-col>
                <b-col
                  lg="6"
                  md="12"
                  class="mb-1"
                >
                  <img
                    loading="lazy"
                    class="img-fluid w-100"
                    src="@/assets/images/home/experiencia1.png"
                    alt=""
                  >
                </b-col>
              </b-row>
            </b-col>
            <b-col
              lg="6"
              md="12"
              class="mb-3"
            >
              <b-row>
                <b-col
                  lg="6"
                  md="12"
                  class="mb-3"
                >

                  <div class="blockquote">
                    <i class="quote-mark">"</i>Dudas resueltas y seguimientos realizados. Muchas gracias por el apoyo!<i class="quote-mark">"</i>
                    <p> <b>Jesus Herrera</b> <br>
                      Central de acumuladores</p>
                  </div>
                </b-col>
                <b-col
                  lg="6"
                  md="12"
                  class="mb-3"
                >
                  <img
                    loading="lazy"
                    class="img-fluid w-100"
                    src="@/assets/images/home/experiencia2.png"
                    alt=""
                  >
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </section>

    <b-row>
      <b-col

        lg="12"
        class="d-block m-auto"
      >
        <b-card class="card-team">

          <b-row>

            <b-col
              lg="6"
              md="12"
            >
              <b-card>
                <img
                  loading="lazy"
                  class="img-fluid w-100"
                  src="@/assets/images/home/equipo.png"
                  alt=""
                >
              </b-card>
            </b-col>
            <b-col
              lg="6"
              md="12"
              class="mb-box-home d-flex align-items-center section-who"
            >
              <div class="container-who">
                <h3 class="title-products">
                  ¿Quiénes somos?
                </h3>
                <p class="">
                  En PromoShop, nos apasiona ofrecer productos promocionales de alta calidad y un servicio excepcional a nuestros clientes. Nuestro equipo de expertos en la industria está comprometido en proporcionar soluciones innovadoras que satisfagan las necesidades de nuestros clientes. En PromoShop siempre estamos buscando superar las expectativas y mantenernos a la vanguardia de las últimas tendencias en productos promocionales.
                </p>
                <img
                  class="s-img"
                  loading="lazy"
                  src="@/assets/images/logo/icon.svg"
                  width="80"
                  alt=""
                >
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="d-block">
      <b-col
        lg="12"
        xl="10"
        class="d-block m-auto text-center pt-5"
      >
        <h2 class="title-products mt-1 mb-3">
          Preguntas frecuentes
        </h2>

        <app-collapse
            accordion
            type="margin"
            class="faq-home"
        >

          <app-collapse-item
              class="faq-title"
              title="¿Cómo puedo hacer un pedido en PromoShop?"
          >
            <ol>
              <li>Accesa a la categoría de productos que te interesa</li>
              <li>Una vez seleccionado el artículo a comprar, elige la marca y color que deseas adquirir</li>
              <li>Presiona el botón "agregar al carrito"</li>
              <li>En caso de querer seleccionar más productos, da clic a la categoría de tu interés para seguir agregándolos a tu compra</li>
              <li>Una vez que estés listo para finalizar tu compra, haz clic en "ver en mi carrito"</li>
              <li>Revisa los detalles de tu compra y da clic en "siguiente"</li>
              <li>Selecciona o edita la dirección en donde quieres recibirlo</li>
              <li>Da clic en "siguiente" y selecciona el método de pago</li>
              <li>Da clic en "pagar"</li>
              <li>Revisa en la bandeja de entrada de tu correo la confirmación de compra</li>
            </ol>

          </app-collapse-item>

          <app-collapse-item title="¿Qué métodos de pago aceptan en Promoshop?">
            <ul>
              <li v-if="!isCentroAmerica">Tarjeta / Crédito / Débito / Oxxo a través de Conekta</li>
              <li>Paga usando puntos</li>
              <li v-if="!isCentroAmerica">Paga mediante SPEI</li>
              <li>Paga mediante el centro de costos (método de pago solo disponible para personal staff de Clarios)</li>
            </ul>
          </app-collapse-item>

          <app-collapse-item title="¿Cómo facturar mi compra?">
            <ol>
              <li>Ingresa a tus pedidos</li>
              <li>Selecciona el pedido a facturar</li>
              <li>Da clic en "Facturar"</li>
              <li>
                Llena el campo de datos con la siguiente información:
                <ul style="list-style-type: lower-alpha;">
                  <li>Registro Federal de Contribuyentes (R.F.C.)</li>
                  <li>Nombre completo o razón social sin régimen de capital</li>
                  <li>Código Postal de su domicilio fiscal vigente</li>
                  <li>Régimen fiscal registrado ante las autoridades fiscales</li>
                </ul>
              </li>
            </ol>
            <p class="pl-2">
              Dichos datos deben ser válidos y vigentes para poder emitir la factura y se pueden obtener en el documento digital que emite el SAT denominado: “Constancia de Situación Fiscal”.
            </p>
            <p class="pl-2">
              En caso de que cualquier dato sea incorrecto o no coincida con los datos que están registrados ante las autoridades fiscales, no podrá emitirse la factura, pues son validados por el SAT como parte del proceso emisión en esta nueva versión 4.0.
            </p>
          </app-collapse-item>

          <app-collapse-item title="¿Cada cuánto tiempo se realiza la carga de puntos?">
            Se realizan cuatro cargas anuales de manera trimestral, cada una con una vigencia de seis meses. Cada vez que se realice una carga de puntos o la vigencia de los mismos se aproxime, se enviará un correo de notificación al usuario.
          </app-collapse-item>

          <app-collapse-item title="¿Los puntos son acumulables?">
            Los puntos sí son acumulables, siempre y cuando estén dentro de la fecha de vigencia indicada.
          </app-collapse-item>

          <app-collapse-item title="¿Las compras con puntos se pueden facturar?">
            No, unicamente las realizadas con pago de tarjeta o transferencia.
          </app-collapse-item>

          <app-collapse-item title="¿Cómo cancelar mi compra?">
            Contacta a servicio al cliente para poder cancelar tu compra. Solamente puedes cancelar las compras que están pendientes de pago.
          </app-collapse-item>

          <app-collapse-item title="Si ya realicé mi pago, ¿a dónde puedo enviar mis datos?" v-if="!isCentroAmerica">
            Puedes enviar tus datos a soporte@promoshopclarios.com o al whatsapp 6647060354 de Lunes a Viernes de 9 am a 5 pm
          </app-collapse-item>

          <app-collapse-item title="¿Puedo hacer la compra de varios productos a la vez?">
            Efectivamente, no hay un límite de compra en cantidad o variedad.
          </app-collapse-item>

          <app-collapse-item title="¿Cuáles son los tiempos de entrega?">
            <p class="pl-2">
              El tiempo de entrega varía de acuerdo al producto, podrás encontrar esta información en la descripción del mismo.
            </p>
            <p class="pl-2" v-if="!isCentroAmerica">
              El rango estimado va desde 15 a 40 días hábiles.
            </p>
          </app-collapse-item>

          <app-collapse-item title="¿Cómo puedo hacer una solicitud especial? (variedad en tallas o marcas)">
            Puedes enviar tu solicitud con las especifiaciones al correo <a v-if="isCentroAmerica" href="mailto:soporte@promoshopclarioscam.com">soporte@promoshopclarioscam.com</a> <a v-if="!isCentroAmerica" href="mailto:soporte@promoshopclarios.com">soporte@promoshopclarios.com</a> o al whatsapp
            <a v-if="isCentroAmerica" href="https://wa.me/526647060354">+52 664 7060 354</a> <a v-if="!isCentroAmerica" href="https://wa.me/6647060354">664 7060 354</a> de Lunes a Viernes de 9 am a 5 pm.

          </app-collapse-item>
        </app-collapse>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BCarousel,
  BCarouselSlide,
  BCard,
  BRow,
  BCol,
  BBadge, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  useShopFiltersSortingAndPagination,
  useShopRemoteData,
  useShopUi,
} from '@/views/e-commerce/e-commerce-shop/useECommerceShop'
import { IS_CENTROAMERICA } from '@/config/app'

export default {
  components: {
    BLink,
    AppCollapse,
    AppCollapseItem,
    BCarousel,
    BCarouselSlide,
    BCard,
    BRow,
    BCol,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      contentWidth: this.$store.state.appConfig.layout.contentWidth,
      search: '',
    }
  },
  computed: {
    isCentroAmerica() {
      return IS_CENTROAMERICA
    },
  },
  created() {
    this.$store.commit('appConfig/UPDATE_CONTENT_WIDTH', 'boxed')
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_CONTENT_WIDTH', this.contentWidth)
  },
  methods: {
    searchProduct() {
      this.$router.push({ name: 'products', params: { searchActive: 'true' } })
    },
    convertCurrencyToFloat(currency) {
      const regex = /[\d.]+/g
      const matches = currency.match(regex)
      const numericString = matches.join('')
      return parseFloat(numericString)
    },
  },
  setup(props) {
    const { category } = props

    const {
      filters, filterOptions, sortBy, sortByOptions,
    } = useShopFiltersSortingAndPagination(category)

    const {
      itemView, itemViewOptions, totalProducts,
    } = useShopUi()

    const { products, fetchProducts } = useShopRemoteData()
    const fetchShopProducts = () => {
      fetchProducts({
        page: filters.value.page,
        search: filters.value.q,
        sortByPrice: sortBy.value.value,
        sortByCreatedAt: 'desc',
        perPage: filters.value.perPage,
        priceRange: filters.value.priceRange,
        categorization: 'liquidacion/liquidacion',
        properties: (filters.value.brands) ? {
          brand: filters.value.brands,
        } : null,
      })
        .then(response => {
          products.value = response.data.products.data.slice(0, 4)
          totalProducts.value = response.data.products.total
        })
    }

    fetchShopProducts()
    return {
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProducts,
      fetchShopProducts,

      // useShopRemoteData
      products,
    }
  },
}

</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_variables.scss';
@import '~@core/scss/base/components/_variables-dark.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";

.section-who{
  padding: 0!important;
  h3{
    color: #4A2268!important;
    font-weight: 700;
  }
  .s-img{
    position: absolute;
    bottom: -10px;
    right: -23px;
    width: 270px;
  }
  p{
    max-width: 500px;
    font-size: 18px;
    line-height: 27px;
    padding-left: 35px;
  }
}
.card-team{
  overflow: hidden;
  .card-body{
    padding: 0;
  }
  .card{
    margin-bottom: 0;
  }
}
.icon_item{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  text-align: center;
  justify-content: center;
  .item {
    width: calc(100% / 9);
    margin-bottom: 25px;
    text-align: center;
    font-weight: bold;
    color: #1d4079;
    -webkit-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    &:hover{
      -webkit-filter: drop-shadow(0px 0px 30px rgba(29, 64, 121));
    }
  }
  img{
    width: 65px;
    display: block;
    margin: 0 auto;
  }

}
.max-he-home{
  max-height: 253px;
}
.box-text {
  display: block;
  margin: 0 auto;
}
.btn-white-red{
  border-color: #ffffff !important;
  background-color: #ffffff !important;
  color: #ea5455!important;
  font-weight: 700;
}

.btn-white-blue{
  border-color: #ffffff !important;
  background-color: #ffffff !important;
  color: rgba(29,64,121,1)!important;
  font-weight: 700;
}

.bg-red-1{
  background: rgb(142, 45, 226);
  background: linear-gradient(149deg, rgba(142, 45, 226,1) 0%, rgba(74,0, 224,1) 80%);
  .card-body{
    align-items: center !important;
    display: flex !important;
  }
}

.bg-red-2{
  background: rgb(249, 83, 198);
  background: linear-gradient(149deg, rgba(249, 83, 198,1) 0%, rgba(74,0, 224,1) 80%);
  .card-body{
    align-items: center !important;
    display: flex !important;
  }
}
.bg-red-3 {
  background: rgb(249, 83, 198);
  background: linear-gradient(149deg, rgba(249, 83, 198, 1) 0%, rgba(74, 0, 224, 1) 80%);
}
.bg-red-4 {
  background: rgb(238, 9, 121);
  background: linear-gradient(149deg, rgba(238, 9, 121, 1) 0%, rgba(255, 106, 0, 1) 80%);
}
.title-products{
  color: #43413D;
  font-size: 30px ;
  text-align: center;
}
.dark-layout{
  .collapse-title{
    color: $theme-dark-headings-color!important;
  }
  .blockquote{
    background-color: $theme-dark-input-bg;
    border:solid 1px $theme-dark-headings-color;
  }
  .title-products{
    font-size: 30px;
    font-weight: 700;
    color: $theme-dark-headings-color!important;
  }
  .bg-grey{
    background-color: $theme-dark-card-bg;
  }
  .img-overlay {
    background-color: $theme-dark-input-bg;
  }
}
.dot {
  width: 400px; height: 400px;
  background: radial-gradient(ellipse at center, #fff,rgba(0,0,0,.0));
}
.img-product {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  display: block;
  margin: 0 auto;
}

.img-product-2 {
  height: 200px;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  margin: 0 auto;
}
.img-product-2-s-1{
  background-size: 180px auto;
}

.img-product-2-s-2{
  background-size: auto 190px;
}

.img-product-2-s-3{
  background-size: auto 185px;
}
.img-product-2-s-4{
  background-size: auto 173px;
}
.yourImg {
  border-radius: 50%;
}
.text-box-title{
  font-size: 30px;
}
.box-image{
  height: 100px;
  width: 100px;
  display: block;
  margin: 0 auto;
}

.dark-layout{
  .icon-img-home{
    background-color: #d0d2d6;
  }
  .text-item{
    color: #d0d2d6!important;
  }
}
.media-body{
  p {
    margin-top: 0;
    margin-bottom: 0px!important;
  }
}
.text-item{
  font-size: 15px;
  font-weight: 700;
  color: #686868!important;

}
.media-center{
  -webkit-box-align: center!important;
  -ms-flex-align: center!important;
  align-items: center!important;
}
.icon-img-home{
  width: 100%;
  height: 65px;
  background-color: $primary;
}

.hieleras-img{
  mask: url('~@/assets/images/icos/hielera.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/hielera.svg') no-repeat center;
}

.spray-img{
  mask: url('~@/assets/images/icos/spray.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/spray.svg') no-repeat center;
}

.textiles-img{
  mask: url('~@/assets/images/icos/t-shirt.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/t-shirt.svg') no-repeat center;
}

.star-img{
  mask: url('~@/assets/images/icos/star.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/star.svg') no-repeat center;
}

.termos-img{
  mask: url('~@/assets/images/icos/thermos.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/thermos.svg') no-repeat center;
}

.mochilas-img{
  mask: url('~@/assets/images/icos/backpack.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/backpack.svg') no-repeat center;
}

.bocinas-img{
  mask: url('~@/assets/images/icos/speaker.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/speaker.svg') no-repeat center;
}

.power-img{
  mask: url('~@/assets/images/icos/power-bank.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/power-bank.svg') no-repeat center;
}

.herramientas-img{
  mask: url('~@/assets/images/icos/tools.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/tools.svg') no-repeat center;
}
.exclusivos-img{
  mask: url('~@/assets/images/icos/lth.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/lth.svg') no-repeat center;
}

.otros-img{
  mask: url('~@/assets/images/icos/headphone.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/headphone.svg') no-repeat center;
}
.list-categories{
  .media{
    margin-bottom: 10px;
  }

}

.flex-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  h2{
    font-size: 16px;
    font-weight: 700;
  }

  .custom-col {
    flex: 0 0 calc(33.333% - 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 200px;
    margin: 0 auto;

    &:last-child {
      margin-right: 0;
    }
  }
}
#carousel-example-generic {
  width: 100vw; /* Esto establece el ancho al 100% del viewport */
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.gradient-border {
  display: inline-block;
  padding: 2px; // Esto se comporta como el borde
  background: linear-gradient(to right, #F6A21B, #EC2C39, #4A2268);
  border-radius: 7px; // 2px (borde) + 5px (border-radius del botón) = 7px
  transition: background 0.3s;

  .category-button {
    display: block;
    background-color: white;
    border: none;
    border-radius: 5px;
    width: 130px!important;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      background-color: transparent;
      color: white;
    }
  }
}

section{
  margin-top: 50px;
  margin-bottom: 50px;
}

.bg {
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.bg-grey{
  background-color: #f2f2f2;
}

.content-wrapper {
  margin-left: 50vw;
  margin-right: 50vw;
  box-sizing: border-box;

  > * {
    box-sizing: content-box;
  }
}
.img-overlay {
  .float-discont{
    z-index: 5!important;
    position: absolute;
    color: #fff;
    font-weight: 700;
    right: 10px;
    top: 10px;
    padding: 1px 6px;
    background-color: #EC2C39;
  }
  background-color: white;
  border: 1px solid #c2c2c2;
  position: relative; // necesitamos posición relativa para posicionar absolutamente el texto
  border-radius: 5px;
  margin-bottom: 15px;
  .img-overlay-image {
    width: 100%;
    height: auto;
    display: block;
  }

  .overlay {
    position: absolute; // esto permitirá que el texto se superponga sobre la imagen
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center; // centrar horizontalmente
    align-items: center; // centrar verticalmente
    opacity: 0; // ocultar por defecto
    background-color: rgba(0, 0, 0, 0.4); // fondo negro con 50% de transparencia
    transition: opacity 0.3s ease-in-out; // transición suave al hacer hover

    .text {
      color: white;
      text-decoration: underline;
      font-weight: 700;
    }
  }

  &:hover {
    .overlay {
      opacity: 1; // mostrar al hacer hover
    }
  }
}
.product-fav{
  .image-container {
    position: relative;
    width: auto; // O define un ancho específico si lo necesitas
    img {
      width: 100%;
      height: auto;
      display: block;
    }

    .overlay-content {
      text-align: left;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 20px;
      padding-left: 15px;
      background: linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent);
      .category-text {
        color: white;
        font-size: 2em;
        margin-bottom: 10px;
      }
      .btn {
        max-width: 160px;
      }
    }
  }
}

.blockquote{
  background-color: #fff;
  padding: 20px 30px;
  font-size: 15px;
  height: 100%;
  p{
    margin-bottom: 0px;
    margin-top: 5px;
    color: #940C4A!important;
  }
  .quote-mark{
    color: #940C4A;
    line-height: 0px;
    font-size: 25px;
  }
}

@media (max-width: 767px) {
  .float-points{
    display: none;
  }
  .cr-values{
    display: none!important;
  }
  .catogory{
    h2{
      color: #43413D;
      font-size: 25px ;
    }
  }
}

@media (min-width:1200px) and (max-width: 1480px) {
  .img-product{
    width: 245px!important;
    height: 245px!important;
    background-size: 120px!important;
  }
}
@media (max-width:991px){
  .gradient-border {
    margin-bottom: 20px;
  }
  .mb-box-home{
    margin-bottom: 30px;
  }
  .icon_item {
    .item{
      width: calc(100% / 5);
    }
  }
}

@media (max-width:1500px){
  .container-who{
    padding: 18px 70px;
  }
.section-who{

  img{
    display: none;
  }
  h3{
    margin-top: 30px;
  }
  p{
    padding: 0;
    max-width: none;
  }
}
}
@media (max-width:576px){
  .icon_item {
    .item{
      width: calc(100% / 3);
    }
  }
}

</style>
